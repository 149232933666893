.form {
	max-width: 770px;
	background: #FFF;
	border: 1px solid #EEE;
	box-sizing: border-box;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	padding: 24px 68px 30px 68px;
  }
  
  @media screen and (max-width: 730px) {
	.form {
	  padding: 20px;
	}
  }

  @media screen and (max-width: 400px) {
	.form {
	  padding: 5px;
	}
  }