.confirm {
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  /* transform: translate(-50%, -50%); */
  inset: 40px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  background: #fff;
  overflow: auto;
  outline: none;
  padding: 20px;
  text-align: center;
  width: auto;
  height: auto;
  max-width: 700px;
  max-height: 250px;
}
.title {
  font-size: 20px;
}
.btn {
  margin: auto;
  width: 100px;
}

@media screen and (max-width: 770px) {
  .title {
    margin-bottom: 60px;
    font-size: 40px;
  }
}

@media screen and (max-width: 940px) {
  .btn {
    font-size: 14px;
    padding: 0px 24px;
    height: 48px;
    line-height: 48px;
  }
}
