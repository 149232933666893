.form {
	max-width: 100%;
}

.inputField {
	flex-basis: 100%;
	border-radius: 10px;
}

.labelClassName {
	font-weight: bold;
	font-size: 20px;
	margin-right: 0;
	margin-bottom: 5px;
}

.button {
	width: 150px;
}

.cancel {
	margin-left: 20px;
	width: 150px;
}

@media screen and (max-width: 400px) {
	.container {
	  text-align: center;
	}
  }