.form {
	max-width: 100%;
}

.inputField {
	flex-basis: 100%;
}

.labelClassName {
	font-weight: bold;
	font-size: 20px;
	margin-right: 0;
	margin-bottom: 10px;
}
