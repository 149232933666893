.question {
  text-align: justify;
  padding: 5px;
  border-radius: 10px;
}

.question__title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 0px;
  padding: 0;
  display: block;
  text-align: justify;
}

.question:hover {
    background-color: lightgray;
}

@media screen and (max-width: 400px) {
  .question__title {
    font-size: 14px;
    line-height: 14px;
    text-align: left;
  }
}
