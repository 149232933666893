.header {
  margin-bottom: 52px;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid rgba(0,0,0,.15);
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

@media screen and (max-width: 1200px) {
  .header {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

@media screen and (max-width: 400px) {
  .header::-webkit-scrollbar {
    width: 0;
  }
}
