.nav {
  height: 90px;
  width: calc(100% - 100px);
  margin-right: 28px;
}

.nav__container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.nav__items {
  display: flex;
  height: 100%;
  flex-direction: row;
  list-style: none;
  padding: 0;
  align-items: center;
}

.nav__item {
  display: flex;
  align-items: center;
  margin-right: 28px;
  height: 100%;
  color: #000000;
  cursor: pointer;
  position: relative;
}
.nav__item:last-child {
  margin-right: 0;
}

.nav__item_active {
  color: #4a61dd;
}
.nav__item_active:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: 20px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #4a61dd;
}

.nav__link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: inherit;
  cursor: inherit;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
}

.nav__link_active {
  border-bottom: 1px solid #4a61dd;
  color: #4a61dd;
}

@media screen and (max-width: 1080px) {
  .nav {
    width: auto;
  }
}

@media screen and (max-width: 400px) {
  .nav__link {
    font-size: 14px;
  }
}
