.container {
  text-align: center;
}
.form {
  display: inline-flex;
  padding: 0;
  box-shadow: none;
  height: 46px;
  width: 90%;
}

.input {
  width: 100%;
}

.inputField {
  flex-basis: 100%;
  border-radius: 10px 0 0 10px;
}

.btn {
  margin: auto 0;
  border-radius: 0 10px 10px 0;
  height: 100%;
  width: 100px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-left: 0;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 940px) {
  .btn {
    font-size: 14px !important;
    padding: 0px 12px;
    height: 100%;
    line-height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-left: none;
  }
  .inputField {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
}
