.html {
  text-align: justify;
  margin-bottom: 52px;
  overflow-x: auto;
}
.html table td {
  border: 1px solid;
}

img {
  max-width: 100%;
  cursor: pointer;
}

.imgModal {
  max-width: 140vh;
  max-height: 140vh;
  width: 100%;
  height: auto;
  cursor: auto;
}

.modal {
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  border: 1px solid lightgrey;
  border-radius: 10px;
  background: #fff;
  outline: none;
  overflow: hidden;
  padding: 10px;
  text-align: center;
  max-width: 90%;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  
}

.shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black ;
  opacity: 0.6;
}

.close {
  text-align: right;
  cursor: pointer;
}
svg {
  width: 20px;
}

svg line {
  stroke: #000;
  stroke-width: 10;
}

.close:hover line {
  stroke: red;
}
