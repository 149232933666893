.date {
  width: 120px;
  text-align: center;
}

.name {
  width: 160px;
  text-align: center;
}

.number {
  text-align: right;
  width: 30px;
}
