.form {
  margin: 0 auto 50px;
}

.button {
  font-size: 18px;
}

.alert li {
  color: red;
  list-style: none;
  text-align: center;
}

.ok li {
  visibility: hidden;
  list-style: none;
  text-align: center;
}