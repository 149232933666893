.img {
	height: 40px;
	margin: 0px auto;
	vertical-align: middle;
}

@media screen and (max-width: 300px) {
	.img {
		height: 30px;
	}
}