.menuLink {
  white-space: nowrap;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  padding: 0;
  font-weight: 500;
  line-height: 19px;
  margin-right: 28px;
}

.menuLink:last-child {
  font-size: 16px;
  margin-right: 0px;
}

.menu {
  display: flex;
  align-items: center;
}

.img {
  width: 23px;
}

.name {
  margin-right: 15px;
}

.logout {
  display: flex;
  align-items: center;
  padding-right: 8px;
}

@media screen and (max-width: 990px) {
  .menuLink {
    font-size: 14px;
    margin-right: 28px;
  }
}

@media screen and (max-width: 900px) {
  .menuLink {
    font-size: 12px;
    margin-right: 28px;
  }
}

@media screen and (max-width: 770px) {
  .menu {
    height: 50px;
  }

  .menuLink {
    padding-bottom: 0px;
    font-size: 14px;
  }

  .menuLink:last-child {
    padding-right: 18px;
  }
}
