.container {
  text-align: center;
}

.title {
  margin-top: 52px;
  font-size: 26px;
  text-align: justify;
}

.text {
  text-align: justify;
  white-space: pre-line;
}

.btn {
  text-align: left;
  padding-left: 0;
}
.edit {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-radius: 10px;
  padding: 0px 24px;
  height: 48px;
  line-height: 48px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: background 0.2s;
  cursor: pointer;
  width: 200px;
  background: #4a61dd;
  color: #fff;
  display: inline-block;
  margin-bottom: 20px;
  text-align: center;
}
.edit:hover {
  background: #2845df;
  opacity: 1;
}
.delete {
  color: white;
  border-radius: 10px;
  font-size: 16px;
  padding: 0;
  margin-left: 20px;
  width: 200px;
  margin-bottom: 20px;
}
.cancel {
  color: white;
  border-radius: 10px;
  font-size: 16px;
  padding: 0;
  width: 200px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.labelClassName {
  font-weight: bold;
  font-size: 20px;
  
}

.textareaField {
  border:none;
  padding-left: 0;
}

@media screen and (max-width: 400px) {
  .container {
    text-align: center;
  }
  .edit {
    font-size: 16px;
    padding: 0;
    height: 48px;
    width: 150px;
  }
  .delete {
    font-size: 16px;
    padding: 0;
    height: 48px;
    width: 150px;
  }
}
