.container {
	max-width: 1200px;
	padding: 0 15px;
	margin: 0 auto;
	width: 100%;
}

@media screen and (max-width: 400px) {
	.container {
		overflow-x: scroll;
	}
}