.problemList {
  margin: 52px auto;
  text-align: justify;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
  display: table;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;
}

.thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
.thead tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.thead th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #a4acb4;
  text-align: center;
}

.tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.tbody tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.tbody td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #a4acb4;
}

.tbody tr:hover {
  cursor: pointer;
  background-color: #e9ecef;
}

@media screen and (max-width: 400px) {
  .problemList {
    font-size: 14px;
  }
}