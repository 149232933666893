.title {
	font-size: 40px;
	margin-top: 0px;
	text-align: center;
	margin-bottom: 40px;
  }
  
  @media screen and (max-width: 770px) {
	.title {
	  font-size: 22px;
	}
  }
  